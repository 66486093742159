import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import normalize from "../utils/tools";
import SEO from "../components/seo"

export default ({ pageContext, data }) => {
  const post = data.nodePage
  const files = pageContext.files
  let article_body_elements = normalize(post.body.processed, files)

  return (
    <Layout>
      <SEO title={post.title} description={post.body.summary} lang={pageContext.lang}/>
      <section>
        <header className="main">
          <h1>{ post.title }</h1>
        </header>
        <div>{article_body_elements}</div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    nodePage(id: { eq: $id }) {
      title
      body {
        summary
        processed
      }
    }
  }
`